import { NgModule, inject } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LoginAuthGuard } from './shared/guards/login.guard';
import { PageAuthGuard } from './shared/guards/page.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing-view/landing-view.module').then(m => m.LandingViewPageModule),
    canActivate: [() => inject(LoginAuthGuard).canActivate()],
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'dull-gallery/:guid',
    loadChildren: () => import('./dull-gallery/dull-gallery.module').then(m => m.DullGalleryModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'create-dull',
    loadChildren: () => import('./create-dull/create-dull.module').then(m => m.CreateDullPageModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'camera-view/:guid',
    loadChildren: () => import('./camera-view/camera-view.module').then(m => m.CameraViewPageModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'image-view/:guid',
    loadChildren: () => import('./image-view/image-view.module').then(m => m.ImageViewPageModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'dull-list',
    loadChildren: () => import('./dull-list/dull-list.module').then(m => m.DullListModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'landing-view',
    loadChildren: () => import('./landing-view/landing-view.module').then(m => m.LandingViewPageModule),
  },
  {
    path: 'dull-grade-result/:guid',
    loadChildren: () => import('./dull-grade-result/dull-grade-result.module').then(m => m.DullGradeResultPageModule),
    canActivate: [OktaAuthGuard, () => inject(PageAuthGuard).canActivate()]
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('./terms-of-use/terms-of-use.module').then(m => m.TermsOfUsePageModule),
    canActivate: [OktaAuthGuard]
  },
  {
    path: 'research-bit-popup/:guid',
    loadChildren: () => import('./research-bit-popup/research-bit-popup.module').then(m => m.ResearchBitPopupPageModule)
  },
  {
    path: 'error-page',
    loadChildren: () => import('./error-page/error-page.module').then(m => m.ErrorPagePageModule)
  },
  {
    path: '**',
    redirectTo: '/dull-list'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
